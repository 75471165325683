import {getBackInStockSettings} from './getBackInStockSettings';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IPropsInjectedByViewerScript} from '../types/app-types';
import {APP_DEFINITION_ID as STORES_APP_DEF_ID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SPECS} from '../specs';
import {LazyValue} from '../commons/lazy';
import {Logger as BiLoggerSite} from '@wix/bi-logger-ec-site';
import {IFedOpsLogger} from '@wix/wixstores-client-storefront-sdk/dist/src/types/native-types';
import {BACK_IN_STOCK_MODAL_APP_NAME, BACK_IN_STOCK_MODAL_OPENED_INTERACTION} from '../constants';

export enum BackInStockCollectingStatus {
  Unknown,
  Pending,
  Yes,
  No,
}

export interface OpenBackInStockModalParams {
  variantId: string;
}

export interface BackInStockServiceProps {
  isBackInStockEnabled: boolean;
  isBackInStockLoading: boolean;
  handleNotifyWhenBackInStock: () => void;
}

export interface BackInStockServiceDependencies {
  siteStore: SiteStore;
  setProps: (props: Partial<IPropsInjectedByViewerScript>) => void;
  resolveProductId: () => string;
  resolveVariantId: () => string;
  resolveFedopsLogger: (fedopsAppName: string) => IFedOpsLogger;
  canCreateBackInStockRequest: () => boolean;
  compId: string;
  biLoggerSite: LazyValue<BiLoggerSite>;
}

type BackInStockRequestModalOpenParams = Record<
  'appDefId' | 'catalogItemId' | 'options_variantId' | 'instance',
  string
>;

export class BackInStockService {
  constructor(private readonly dependencies: BackInStockServiceDependencies) {}

  public async initialize() {
    if (!this.isExperimentEnabled) {
      return;
    }

    await this.fetchIsCollectingRequests();
  }

  public get props(): BackInStockServiceProps {
    return {
      isBackInStockEnabled: this.isBackInStockEnabled,
      isBackInStockLoading: this.collectingStatus === BackInStockCollectingStatus.Pending,
      handleNotifyWhenBackInStock:
        /* istanbul ignore next todo(titk@wix.com): remove when modal is implemented */ () => {
          const isValid = this.dependencies.canCreateBackInStockRequest();
          //eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.dependencies.biLoggerSite.value.bisClickOnNotifyMeButtonToOpenModal({
            eligible: isValid,
            option: isValid ? this.dependencies.resolveVariantId() : '',
            catalogItemId: this.dependencies.resolveProductId(),
          });
          if (isValid) {
            this.reportFedopsModalOpened();
            //eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.openBackInStockModal({
              variantId: this.dependencies.resolveVariantId(),
            });
          }

          this.updateProps();
        },
    };
  }

  public get isBackInStockEnabled(): boolean {
    return this.isExperimentEnabled && this.collectingStatus === BackInStockCollectingStatus.Yes;
  }

  private get isExperimentEnabled() {
    return this.dependencies.siteStore.experiments.enabled(SPECS.BACK_IN_STOCK_PRODUCT_PAGE_BUTTON);
  }

  private updateProps() {
    this.dependencies.setProps(this.props);
  }

  private collectingStatus = BackInStockCollectingStatus.Unknown;

  /* istanbul ignore next todo(aleksandrg@wix.com): remove when modal is implemented */
  private getBackInStockModalUrl(openParams: BackInStockRequestModalOpenParams): string {
    const url = new URL(`https://ecom.wix.com/back-in-stock-request-modal`);
    for (const [key, value] of Object.entries(openParams)) {
      url.searchParams.append(key, value);
    }
    return url.toString();
  }

  /* istanbul ignore next todo(titk@wix.com): remove when modal is implemented */
  private async openBackInStockModal({variantId}: OpenBackInStockModalParams) {
    if (!this.isExperimentEnabled) {
      return;
    }

    const productId = this.dependencies.resolveProductId();

    const modalOpenParam: BackInStockRequestModalOpenParams = {
      options_variantId: variantId,
      catalogItemId: productId,
      appDefId: STORES_APP_DEF_ID,
      instance: this.dependencies.siteStore.instanceManager.getInstance(),
    };

    // todo(aleksandrg@wix.com): this is a POC implementation. Do in correct platformized way. See EE-30945
    return this.dependencies.siteStore.windowApis.openModal(
      this.getBackInStockModalUrl(modalOpenParam),
      {
        width: 603,
        height: 348,
        theme: 'BARE',
      },
      this.dependencies.compId
    );
  }

  private async fetchIsCollectingRequests() {
    try {
      this.collectingStatus = BackInStockCollectingStatus.Pending;

      const result = await getBackInStockSettings(this.dependencies.siteStore);

      this.collectingStatus = result.backInStock.settings.isCollectingRequests
        ? BackInStockCollectingStatus.Yes
        : BackInStockCollectingStatus.No;
    } catch (e) {
      /* istanbul ignore next */
      this.collectingStatus = BackInStockCollectingStatus.Unknown;
    } finally {
      this.updateProps();
    }
  }

  private reportFedopsModalOpened() {
    this.dependencies
      .resolveFedopsLogger(BACK_IN_STOCK_MODAL_APP_NAME)
      .interactionStarted(BACK_IN_STOCK_MODAL_OPENED_INTERACTION);
  }
}
